import { getOrderLink, isSitchProdAccount } from '@/util-functions/misc-utils';
import { format } from 'date-fns';

export const acceptedEmailBody = (userPaymentRecord: UserPaymentRecord, stripeAccount: StripeAccount) => {
  const businessName = stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName;
  const formattedDate = format(userPaymentRecord.dateCreated.toDate(), 'MMM, dd, yyyy h:mm a');
  const orderLink = getOrderLink(userPaymentRecord);
  const ret = {
    subject: `Your ${businessName} order has been received`,
    body: `Hi, we wanted to inform you that your order has been received. This order was placed on ${formattedDate}.  You can review your order here: ${orderLink}
    `,
  };
  return ret;
};

export const rejectedEmailBody = (userPaymentRecord: UserPaymentRecord, stripeAccount: StripeAccount) => {
  const businessName = stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName;
  const formattedDate = format(userPaymentRecord.dateCreated.toDate(), 'MMM, dd, yyyy h:mm a');
  const orderLink = getOrderLink(userPaymentRecord);
  return {
    subject: `Your ${businessName} order cannot be fufilled`,
    body: `Hi, we wanted to inform you that your order cannot be fufilled. This order was placed on ${formattedDate}.  You can review your order here: ${orderLink}
    `,
  };
};

export const dispatchedEmailBody = (userPaymentRecord: UserPaymentRecord, stripeAccount: StripeAccount) => {
  const businessName = stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName;
  const formattedDate = format(userPaymentRecord.dateCreated.toDate(), 'MMM, dd, yyyy h:mm a');
  const orderLink = getOrderLink(userPaymentRecord);
  let salutation = 'Hi';
  if (userPaymentRecord.personWhoPaidName) {
    salutation = `Hi ${userPaymentRecord.personWhoPaidName.split(' ')[0]}`;
  }
  const ret = {
    subject: `Your ${businessName} order is on its way`,
    body: `${salutation}, we wanted to inform you that your order is on its way. This order was placed on ${formattedDate}. You can review your order here: ${orderLink}
    `,
  };
  if (userPaymentRecord.trackingLink) {
    ret.body += `%0D%0A%0D%0AYour order can be tracked here: ${userPaymentRecord.trackingLink}`;
  }
  if (isSitchProdAccount()) {
    ret.body += '%0D%0A%0D%0AThank you for your order! If you have any questions or concerns feel free to reply to this email.';
  }
  return ret;
};

export const deliveredEmailBody = (userPaymentRecord: UserPaymentRecord, stripeAccount: StripeAccount) => {
  const businessName = stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName;
  const formattedDate = format(userPaymentRecord.dateCreated.toDate(), 'MMM, dd, yyyy h:mm a');
  const orderLink = getOrderLink(userPaymentRecord);
  return {
    subject: `Your ${businessName} order has been delivered`,
    body: `Hi, we wanted to inform you that your order has been delivered. This order was placed on ${formattedDate}.  You can review your order here: ${orderLink}
    `,
  };
};

export const readyForPickUpEmailBody = (userPaymentRecord: UserPaymentRecord, stripeAccount: StripeAccount) => {
  const businessName = stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName;
  const formattedDate = format(userPaymentRecord.dateCreated.toDate(), 'MMM, dd, yyyy h:mm a');
  const orderLink = getOrderLink(userPaymentRecord);
  return {
    subject: `Your ${businessName} order is ready for pick-up`,
    body: `Hi, we wanted to inform you that your order is ready for pick-up. This order was placed on ${formattedDate}.  You can review your order here: ${orderLink}
    `,
  };
};

export const onHoldEmailBody = (userPaymentRecord: UserPaymentRecord, stripeAccount: StripeAccount) => {
  const businessName = stripeAccount.customerFacingBusinessName || stripeAccount.statementDescriptor || stripeAccount.displayName;
  const formattedDate = format(userPaymentRecord.dateCreated.toDate(), 'MMM, dd, yyyy h:mm a');
  const orderLink = getOrderLink(userPaymentRecord);
  return {
    subject: `Your ${businessName} order is on hold`,
    body: `Hi, we wanted to inform you that your order is temporarily on hold. This order was placed on ${formattedDate}.  You can review your order here: ${orderLink}
    `,
  };
};
