
import Vue from 'vue';
import { modeSubmissionsMixin } from '@/mixins';
import { eCustomFieldTypes, eModeType } from '@/enums';
import { ExportToCsv } from 'export-to-csv';
import { saveSettings } from '@/util-functions/user-utils';
import { getCustomFieldValue } from '@/util-functions/misc-utils';
import { t } from '@/util-functions/language-utils';
import { hideLoading, showLoading } from '@/util-functions/loading-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { apiUrl } from '@/util-functions/initialization-utils';

interface ContactSubmission extends Submission {
  name?: string;
  email?: string;
  phoneNumber?: string;
  customFields?: { [id: string]: CustomField };
}

export default Vue.extend({
  mixins: [modeSubmissionsMixin],
  data(): SubmissionPageData {
    const headers = [
      { text: t.submittedAt, value: 'dateCreated' },
      { text: t.primaryValue, value: '' },
      { text: t.actions, value: '', sortable: false, align: 'center' },
    ];
    return {
      headers,
    } as SubmissionPageData;
  },
  computed: {
    showNewItemAlert: {
      get(): boolean {
        return this.$store.state.currUser.settings.showNewCustomFormAlert;
      },
      set(val: boolean) {
        saveSettings({ showNewCustomFormAlert: val });
      },
    },
    relevantModes(): CustomFormMode[] {
      return (Object.values(this.$store.state.modes) as AnyMode[]).filter((mode: AnyMode) => {
        return mode.type === eModeType.customForm;
      }) as CustomFormMode[];
    },
    getNameEmailPhoneFromCurrentSubmission(): {
      name: string;
      email: string;
      phoneNumber: string;
    } {
      const currSubmission = this.currSubmission as ContactSubmission;
      const customFieldsArray = Object.values(currSubmission.customFields || {});

      const firstCustomNameFieldValue = customFieldsArray?.find((field: CustomField) => {
        return field.name.toLowerCase().includes('name');
      })?.value;
      const firstCustomEmailFieldValue = customFieldsArray?.find((field: CustomField) => {
        return field.type === eCustomFieldTypes.email;
      })?.value;
      const firstCustomPhoneFieldValue = customFieldsArray?.find((field: CustomField) => {
        return field.type === eCustomFieldTypes.phoneNumber;
      })?.value;
      const name = currSubmission.name || firstCustomNameFieldValue || '';
      const email = currSubmission.email || firstCustomEmailFieldValue || '';
      const phoneNumber = currSubmission.phoneNumber || firstCustomPhoneFieldValue || '';
      return {
        name,
        email,
        phoneNumber,
      };
    },
    canAddAsContact(): boolean {
      return Boolean(this.getNameEmailPhoneFromCurrentSubmission.name && (this.getNameEmailPhoneFromCurrentSubmission.email || this.getNameEmailPhoneFromCurrentSubmission.phoneNumber));
    },
  },
  methods: {
    getCustomFieldValue,
    getApplicableCustomFields(customFields: { [id: string]: CustomFieldData }) {
      const customFormMode = this.currSelectedMode as CustomFormMode;
      const customFieldArray = Object.entries(customFields)
        .map((val) => {
          return {
            id: val[0],
            ...val[1],
          };
        })
        .filter((customField) => {
          return customField.type !== eCustomFieldTypes.nonInputTextDescription;
        })
        .sort((a, b) => {
          let aIndex = -1;
          customFormMode.customFields.find((val, index) => {
            aIndex = index;
            return val.id === a.id;
          });
          let bIndex = -1;
          customFormMode.customFields.find((val, index) => {
            bIndex = index;
            return val.id === b.id;
          });

          return aIndex - bIndex;
        });
      return customFieldArray;
    },
    exportCsv() {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: t.exportCsvRecordsTitle.supplant([this.currSelectedMode?.name]),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(
        (this.submissionArray as CustomFormSubmission[]).map((customFormSubmission) => {
          const returnObj: any = {};
          const typedCurrSelectedMode = this.currSelectedMode as CustomFormMode;

          if (typedCurrSelectedMode.requireName) {
            returnObj[t.name] = customFormSubmission.name || t.none;
          }
          if (typedCurrSelectedMode.requireEmail) {
            returnObj[t.email] = customFormSubmission.email || t.none;
          }
          if (typedCurrSelectedMode.requirePhoneNumber) {
            returnObj[t.phoneNumber] = customFormSubmission.phoneNumber || t.none;
          }

          if (customFormSubmission.customFields) {
            Object.values(customFormSubmission.customFields).forEach((customField) => {
              returnObj[customField.name] = this.getCustomFieldValue(customField, true);
            });
          }

          return returnObj;
        })
      );
    },
    getFirstCustomFieldValue(customFormSubmission: CustomFormSubmission): string {
      return this.getCustomFieldValue(this.getApplicableCustomFields(customFormSubmission?.customFields)?.[0]);
    },
    async onAddContact() {
      const currSubmission = this.currSubmission as ContactSubmission;

      if (!this.canAddAsContact) {
        showError('Cannot add as a contact.');
        return;
      }

      interface ContactData {
        firstName: string;
        middleName: string;
        lastName: string;
        photo: string;
        photoType: string;
        url: string;
        cellPhone: string;
        workPhone: string;
        note: string;
        email: string;
        title: string;
        street: string;
        city: string;
        stateProvince: string;
        postalCode: string;
        countryRegion: string;
        organization: string;
        socialUrls: {
          displayName: string;
          url: string;
        }[];
      }

      let note = '';

      const customFieldsArray = Object.values(currSubmission.customFields || {});
      const firstCustomMessageFieldValue =
        customFieldsArray?.find((field: CustomField) => {
          return field.name.toLowerCase().includes('message');
        })?.value || '';

      note = firstCustomMessageFieldValue || '';

      function scrubInvalidChars(replaceStr: string) {
        return replaceStr?.replaceAll('—', ' ') || '';
      }

      const name = this.getNameEmailPhoneFromCurrentSubmission.name;
      const email = this.getNameEmailPhoneFromCurrentSubmission.email;
      const phoneNumber = this.getNameEmailPhoneFromCurrentSubmission.phoneNumber;      

      const contactData: ContactData = {
        firstName: '',
        middleName: '',
        lastName: '',
        cellPhone: scrubInvalidChars(phoneNumber.toString()?.replaceAll('[^\\d]', '') || ''), // toString() in case of legacy number types.
        email: scrubInvalidChars(email),
        note,
        photo: '',
        photoType: '',
        url: '',
        workPhone: '',
        title: '',
        street: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        countryRegion: '',
        organization: '',
        socialUrls: [],
      };

      const nameParts = name.split(' ');
      contactData.firstName = scrubInvalidChars(nameParts[0]);
      switch (nameParts.length) {
        case 2:
          contactData.lastName = scrubInvalidChars(nameParts[1]);
          break;
        case 3:
          contactData.middleName = scrubInvalidChars(nameParts[1]);
          contactData.lastName = scrubInvalidChars(nameParts[2]);
          break;
      }

      const blobApiFetch = (endpoint: string, body: any, callback: (jsonResponse: any) => void = () => undefined): Promise<void> => {
        showLoading();
        return fetch(`${apiUrl}/${endpoint}`, {
          method: 'post',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *client
          body: JSON.stringify(body),
        })
          .then((response) => {
            response
              .blob()
              .then(callback)
              .catch((error: any) => {
                showError('Error getting blob from the response. ' + error?.message);
              });
          })
          .catch((error: any) => {
            showError('Error making fetch call. ' + error?.message);
          })
          .finally(() => {
            hideLoading();
          });
      };

      const fileDownload = (data: any, filename: string, mime: string, bom: any = undefined) => {
        const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
        const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
        if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          (window.navigator as any).msSaveBlob(blob, filename);
        } else {
          const blobURL = (window.URL ? window.URL : window.webkitURL).createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', filename);

          // Safari thinks _blank anchor are pop ups. We only want to set _blank
          // target if the browser does not support the HTML5 download attribute.
          // This allows you to download files in desktop safari if pop up blocking
          // is enabled.
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
          }

          document.body.appendChild(tempLink);
          tempLink.click();

          // Fixes "webkit blob resource error 1"
          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
          }, 100);
        }
      };

      blobApiFetch('vcard', contactData, (blob) => {
        if (!currSubmission.name) {
          return;
        }
        fileDownload(blob, `${currSubmission.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.vcf`, 'text/vcard');
      }).catch((err: any) => {
        console.error('Could not fetch the vcf file.', err?.message);
      });
    },
  },
});
